import React from 'react'

const NotFoundPage = () => {
  return (
    <div>
      <p>Page not found 404</p>
    </div>
  )
}

export default NotFoundPage
